.anchor {

	&__list {
		position: relative;
		left: -.5rem;

		margin-bottom: .5rem;

		width: calc(100% + 1rem);

		a {

			margin: 0 .5rem;
		}
	}
}
