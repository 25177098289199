.buttons-wrap {

	.button {

		margin-left: 1rem;

		&:last-child {

			margin-left: 0;
		}
	}
}

button {

	outline: none;
}

.button {

	text-transform: uppercase;
	font-weight: $global-weight-bold;
	line-height: 1.4;
	-webkit-tap-highlight-color: transparentize($primary-color, .5);

	// states
	&:focus {

		outline: none;
	}

	&:hover:before {

		display: none;
	}

	&.secondary {

		background-color: #b1b1b1;

		&,
		&:hover,
		&:focus {

			color: $white;
		}
	}

	// hollow button modifier
	&.ghost {

		padding: 18px 28px;

		border: 3px solid $primary-color;
		background: transparent;
		transition: border-color .25s ease, background-color .25s ease, color .25s ease;

		color: $primary-color;

		// states
		&:focus,
		&:hover {

			border-color: scale-color($primary-color, $lightness: -20%);
		}

		&.disabled {

			opacity: 1;
			cursor: auto;
			background-color: transparent;
			border-color: $input-background;

			color: $input-background;

			&:focus,
			&:hover {

				background-color: transparent;
				border-color: $input-background;

				color: $input-background;
			}
		}

		&.active{
			background-color: $primary-color;
			color: $white;
		}
	}

	&__list {

		display: flex;
		align-items: flex-start;
		gap: 1rem;
	}
}
