.print {

	@media screen {
		padding-bottom: 5rem;
	}

	@media print {
		padding-bottom: 0;
	}

	&__headline {
		@extend .headline-2-primary;
		margin-bottom: 0;
		-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
		color-adjust: exact !important; /* Firefox 48 – 96 */
		print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
		color: map-get($foundation-palette, primary) !important;

		&--red {
			-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
			color-adjust: exact !important; /* Firefox 48 – 96 */
			print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
			color: map-get($foundation-palette, primary) !important;
		}

		&--ul {
			padding-bottom: 1rem;
			border-bottom: 2px solid $white;
		}

		&--small {
			font-size: 1rem;
		}
	}


	&__header {

		@media print {
			padding-bottom: 3rem;
		}

		&--url {

			@media screen {
				display: none;
			}

			@media print {
				display: block;
				position: absolute;
				top: 2.25rem;
				right: 3rem;
				font-family: 'Open Sans', sans-serif;
				font-weight: 900;
				font-size: 1.5rem;
				-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
				color-adjust: exact !important; /* Firefox 48 – 96 */
				print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
				color: map-get($foundation-palette, primary) !important;
				text-transform: uppercase;
			}
		}

		&--logo {

			@media screen {
				display: none;
			}

			@media print {

				display: block;
				position: absolute;
				top: 1rem;
				left: 3rem;

				svg {
					height: 3rem;
					width: auto;
				}
			}
		}
	}

	&__bg {
		padding: 1rem;
		break-inside: avoid;
		-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
		color-adjust: exact !important; /* Firefox 48 – 96 */
		print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
		background-color: $light-gray !important;
		font-size: 12px;

	}

	&__button-container {
		@media screen {
			display: flex;
			justify-content: flex-end;
			margin-top: 1rem;
		}

		@media print {
			display: none;
		}
	}

	&__highlight {
		font-size: 1rem;
	}

	&__avoid-break {
		break-inside: avoid;
	}
}
