select {

	padding-right: 1.3125rem;

	background-position: right 0 center;
	//noinspection CssUnknownTarget
	background-image: url('/assets/icons/icon-arrow-dropdown.svg');
	background-size: auto;

	&.is-placeholder {
		color: map-get($foundation-palette, secondary);
	}
}

