// Style Selected Text
::selection {

	background: $primary-color;

	color: $white;
}

body {

	@media print {

		-webkit-print-color-adjust: exact;
	}
}

.large-margin-bottom {

	margin-bottom: 18px;

	@include breakpoint(medium) {

		margin-bottom: 40px;
	}
}

.large-margin-top {

	margin-top: 18px;

	@include breakpoint(medium) {

		margin-top: 40px;
	}
}

.centered-text {

	margin: 0 0 1rem 0;
	display: inline-block;
}

.input-group-button .button.tooltip-trigger {

	padding: 20px 10px;
}

.cell-content, .grid-padding-y > .cell.cell-content {

	padding-top: 12px;
	min-height: calc(100% - 112px); //minus header and footer

	@include breakpoint(medium) {

		padding-top: 50px;
	}
}

hr {

	margin: 0 0 16px;

	border-bottom: 3px solid $body-font-color;
}

strong {

	font-weight: $global-weight-bold;
}

table {

	width: 100%;
}

.text-secondary {

	color: map-get($foundation-palette, secondary);
}

a {
	position: relative;

	-webkit-tap-highlight-color: transparentize($primary-color, .5);

	text-decoration: none;

	@include breakpoint(x-medium) {

		&:before {
			position: absolute;
			bottom: 0;
			left: 0;

			width: 100%;
			height: 1px;

			background-color: $primary-color;
			visibility: hidden;
			transform: scaleX(0);
			transition: all .3s ease-in-out;

			content: '';
		}

		&:hover:before {

			visibility: visible;
			transform: scaleX(1);
		}
	}

	&.external {

		svg {

			margin-left: .5rem;
			margin-bottom: -.25rem;
		}
	}
}

.mobile-burger {
	.navigation-element {

		padding-left: 0 !important;
		height: auto !important;

		line-height: 0 !important;
	}
}

// Foundation overwrite
.medium-grid-frame {

	overflow: visible;
}

// General visibility classes
.is-hidden {

	display: none;
}

// font utility
.font-bold {

	font-weight: $global-weight-bold;
}

//utility classes
.grow {
	flex-grow: 1;
}

.space-between {

	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.justify-center {

	display: flex;
	justify-content: center;
}

.justify-end {

	display: flex;
	justify-content: flex-end;
}

.align-right {
	text-align: right;
}
