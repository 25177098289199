@import '../assets/foundation-settings';

.card {

	margin-bottom: 10px;
	padding: 24px;
	width: 100%;

	background-color: $white;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, .05);

	@include breakpoint(x-medium) {

		margin-bottom: 20px;
		padding: 40px;
		width: calc(50% - 11px);
	}

	@include breakpoint(large) {

		width: calc(50% - 10px);
	}

	.edit {
		position: absolute;
		top: 0;
		right: 0;
	}

	p {
		margin-bottom: 0;

		&.date {

			margin-bottom: 10px;
		}
	}

	.button {

		margin-top: 15px;
		margin-bottom: 0;

		@include breakpoint(medium) {

			margin-top: 20px;
		}

		@include breakpoint(large) {

			margin-top: 40px;
		}
	}
}

.card-grid {

	width: 100%;
}

.wrapper-for-icon {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;

	@include breakpoint(x-medium) {
		top: 2.5rem;
		right: 2.5rem;
	}

	[class*='icon-']:before {

		font-size: 20px;
	}

	.icon-delete {

		margin-right: 20px;

		cursor: pointer;
	}

	.icon-edit {

		margin-right: 28px;

		cursor: pointer;
	}

	.ui-button {

		transition: color ease .25s;

		color: map-get($foundation-palette, secondary);

		&:hover {

			color: map-get($foundation-palette, primary);

			&:before {

				display: none;
			}
		}
	}
}
