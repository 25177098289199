@mixin placeholder() {
	$selectors: (
		'::placeholder',
		':-ms-input-placeholder',
		':-moz-placeholder',
		'::-moz-placeholder',
		'::-webkit-input-placeholder',
	);

	@each $selector in $selectors {
		&#{$selector} {
			@content;
		}
	}
}
