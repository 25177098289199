.floating-box-background {

	height: 100vh;

	//noinspection CssUnknownTarget
	background: url('/assets/images/login-background.jpg') center no-repeat;
	background-size: cover;
}

.floating-box-card {

	padding: 40px 1rem;
	max-width: 700px;

	background-color: $white;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
}

.floating-box-logo {

	margin-bottom: 40px;
	height: 46px;
}
