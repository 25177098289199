// open-sans-regular - latin
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	//noinspection CssUnknownTarget
	src: url('/assets/fonts/open-sans-v15-latin-regular.eot'); // IE9 Compat Modes
	//noinspection CssUnknownTarget
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
	url('/assets/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
	url('/assets/fonts/open-sans-v15-latin-regular.woff2') format('woff2'), // Super Modern Browsers
	url('/assets/fonts/open-sans-v15-latin-regular.woff') format('woff'), // Modern Browsers
	url('/assets/fonts/open-sans-v15-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
	url('/assets/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); // Legacy iOS
	font-display: swap;
}

// open-sans-600 - latin
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: $global-weight-bold;
	//noinspection CssUnknownTarget
	src: url('/assets/fonts/open-sans-v15-latin-600.eot'); // IE9 Compat Modes
	//noinspection CssUnknownTarget
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
	url('/assets/fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), // IE6-IE8
	url('/assets/fonts/open-sans-v15-latin-600.woff2') format('woff2'), // Super Modern Browsers
	url('/assets/fonts/open-sans-v15-latin-600.woff') format('woff'), // Modern Browsers
	url('/assets/fonts/open-sans-v15-latin-600.ttf') format('truetype'), // Safari, Android, iOS
	url('/assets/fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); // Legacy iOS
	font-display: swap;
}
