// Upload Inputs
.upload {

	p {

		font-weight: bold;
	}

	&__input {

		display: flex;
	}

	&__filename {
		@extend textarea;

		padding: 1rem;
		display: flex;
		align-items: center;
	}

	&__list {

		gap: 1rem;
		flex-wrap: wrap;
	}

	&__groups {

		display: flex;
		align-items: baseline;
	}

	&__checkbox {

		margin-right: .25rem;
		margin-left: 1rem;
		width: 1rem;
		min-height: 0;

		cursor: pointer;
		-webkit-user-select: none;
		user-select: none;
	}

	&__checkbox:first-child {

		margin-left: 0;
	}
}
