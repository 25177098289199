// Local Variables
$translucent-bg: rgba($black, .5);
$grey-07: #ececed;
$grey-04: #f5f5f5;
$c-font: #3a3a39;

%is-closed {

	visibility: hidden;
	opacity: 0;
}

%is-open {

	visibility: visible;
	opacity: 1;
}

.overlay {
	position: fixed;
	z-index: 200;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background: $translucent-bg;
	transition: visibility .25s ease, opacity .25s ease;

	// states
	&.is-closed {
		@extend %is-closed;
	}

	&.is-open {
		@extend %is-open;
	}

	&__inner {
		position: absolute;
		top: 50%;
		left: 50%;

		width: 100%;

		transform: translate(-50%, -50%);
	}
}

.modal {

	$this: &;
	position: relative;

	max-height: calc(100vh - 10vh);

	padding: 1rem;

	background: $white;


	@media screen and (max-height: 1200px) {

		overflow-x: hidden;
		overflow-y: scroll;

		.modal__close {
			left: calc(100% + 2rem);
		}
	}

	@media screen and (min-width: 720px) {

		padding: 2rem;

	}

	// states
	&.is-closed {
		@extend %is-closed;
	}

	&.is-open {
		@extend %is-open;
	}

	// elements
	hr {

		margin-bottom: 2rem;
		height: 1px;

		border-bottom: 3px solid $c-font;
	}

	&__close {
		position: absolute;
		right: 0;
		left: auto;
		bottom: calc(100% + 1rem);

		cursor: pointer;

		@include breakpoint(x-medium) {
			right: auto;
			left: calc(100% + 1rem);
		}

		i {
			position: fixed;
			display: block;
		}

	}

	// modifiers
	&--wishlist {

		#{$this}__headline {

			margin-bottom: 2rem;
		}

		#{$this}__list {

			margin-bottom: 2rem;

			overflow: hidden;
		}

		#{$this}__item {

			margin-bottom: 1rem;

			&:last-child {

				margin-bottom: 0;
			}
		}
	}
}
