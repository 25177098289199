.order-wrap {

	display: flex;
	flex-direction: column;
}

@for $i from 1 through 5 {
	.order-#{$i} {

		order: #{$i};
	}
}
