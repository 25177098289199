@use '../mixins/util';

.checkbox {
	position: relative;

	margin-bottom: 1.25rem;
	padding-left: 50px;
	min-height: 30px;
	display: block;

	cursor: pointer;
	user-select: none;

	&--nolabel {

		margin: 0;
		padding-left: 30px;
		display: inline-block;
	}

	[type='checkbox'] {
		position: absolute;

		height: 0;
		width: 0;

		opacity: 0;
		cursor: pointer;
	}

	.checkbox-label {

		color: $black;
		font-size: 16px;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;

		width: 30px;
		height: 30px;

		background-color: $input-background;

		content: '';

		// Create the checkmark/indicator (hidden when not checked)
		&:after {
			top: 0;
			left: 0;
			position: absolute;

			width: 30px;
			height: 30px;

			opacity: 0;
			transition: opacity .2s ease-in-out;
			//noinspection CssUnknownTarget
			background: url('/assets/icons/icon-check.svg') no-repeat center;

			content: '';
		}

		&:hover:after,
		.checkbox-label:hover ~ &:after {

			opacity: .5;
		}
	}

	// Show the checkmark when checked
	[type='checkbox']:checked ~ .checkmark:after {

		opacity: 1;
	}
}

.floating-label-container {
	position: relative;

	input {
		@include util.placeholder {
			color: transparent;
			opacity: 0;
		}

		&:not(:placeholder-shown),
		&.filled {

			padding-top: 2rem;
		}
	}

	select {
		&.filled {

			padding-top: 1rem;
		}
	}

	textarea {
		&.filled {

			padding: 2rem 1.3125rem 1.3125rem;
		}
	}

	&.input-group-field input, select {

		margin: 0;
	}

	&.error {
		input, select {

			border: 1px solid map-get($foundation-palette, primary);
		}

		.floating {

			padding-top: 0;

			background-color: map-get($foundation-palette, primary);

			color: $white;
		}
	}
}

label.floating,
select.floating {
	position: absolute;
	top: 0;
	left: 0;

	padding-left: 1.3125rem;
	padding-top: 1.2rem;
	width: 100%;

	pointer-events: none;
	transition: .2s ease-in-out;

	font-size: 1rem;
	color: map-get($foundation-palette, secondary);
}

input:not(:placeholder-shown) + label.floating,
input.filled + label.floating,
textarea.filled + label.floating,
select + label.floating {

	padding-top: 0;

	background-color: map-get($foundation-palette, secondary);

	color: $white;
	font-size: 12px;
}

// highlight state
.highlight label.floating {

	font-weight: $global-weight-bold;
}

input, select {
	&.ng-invalid {
		&:not(.filled).ng-dirty {
			padding-top: 2rem;

			+ label {
				padding-top: 1.5rem;
			}
		}

		&.ng-dirty, &.filled {
			+ label {

				&:not(:placeholder-shown),
				.is-filled & {

					border-top: 2px solid $primary-color;
				}


				&:after {
					content: var(--error-message);
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					background-color: $primary-color;
					font-size: 12px;
					color: #fff;
					padding-left: 1.3125rem;
				}
			}
		}
	}

	&[disabled] {

		opacity: .5;
		cursor: not-allowed;

		+ label {

			opacity: .5;
		}
	}
}

input:not(:placeholder-shown).ng-invalid + label.floating,
input.filled.ng-invalid + label.floating {
	background-color: $primary-color;
}

	textarea {
		padding: 1.3125rem 1.3125rem 2rem;
	}
