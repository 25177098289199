// Cookie Message
.cookie-message a {

	color: $white;
	text-decoration: underline;

	// Hover state
	&:hover {

		border: none;

		color: transparentize($white, .4);

		&:before {

			display: none;
		}
	}
}
