// General headline styles
.headline {
	&#{&} { // increasing specificity to 2

		// colors
		&--black {

			color: $black;
		}

		&--grey {

			color: $body-font-color;
		}
	}
}

.headline-primary {

	margin-bottom: 18px;

	font-size: 22px;
	font-weight: $global-weight-bold;
	color: map-get($foundation-palette, primary);
	line-height: 27px;
	text-transform: uppercase;

	@include breakpoint(medium) {

		margin-bottom: 22px;

		font-size: 26px;
		line-height: 48px;
	}

	@include breakpoint(large) {

		margin-bottom: 35px;

		font-size: 36px;
		line-height: 40px;
	}

	&--small-space {

		margin-bottom: 8px;

		@include breakpoint(medium) {

			margin-bottom: 12px;
		}

		@include breakpoint(large) {

			margin-bottom: 10px;
		}
	}
}

.headline-primary + p {

	margin-top: -10px;
	margin-bottom: 18px;

	@include breakpoint(medium) {

		margin-bottom: 40px;
	}

	@include breakpoint(large) {

		margin-top: -25px;
	}
}

.headline-2-primary {
	@extend .headline-primary;

	margin-bottom: 22px;

	font-size: 18px;
	line-height: 18px;

	@include breakpoint(medium) {

		margin-bottom: 35px;

		line-height: 26px;
		font-size: 20px;
	}

	@include breakpoint(large) {

		margin-bottom: 40px;

		font-size: 24px;
	}

	&.has-icon {
		position: relative;

		padding: 0 0 0 40px;

		word-break: break-word;
		hyphens: auto;

		i {
			position: absolute;
			left: 0;
			top: 12px;
		}
	}
}

.headline-link {

	margin-bottom: 20px;
	display: inline-block;

	text-transform: uppercase;
	font-weight: $global-weight-bold;

	@include breakpoint(large) {

		margin-bottom: 12px;
	}

	.dashboard & {
		@include breakpoint(medium) {

			margin-bottom: 30px;
		}
	}
}

.subheadline-main {

	margin-bottom: 50px;
	display: none;

	color: $white;
	font-size: 22px;

	@include breakpoint(medium) {

		display: block;

		font-size: 26px;
	}

	@include breakpoint(large) {

		margin-bottom: 100px;

		font-size: 36px;
	}
}
