// Generated by Glyphter (http://www.glyphter.com) on  Thu Aug 30 2018
@font-face {

	font-family: 'Glyphter';
	//noinspection CssUnknownTarget
	src: url('/assets/icon-font/Glyphter.eot');
	//noinspection CssUnknownTarget
	src: url('/assets/icon-font/Glyphter.eot?#iefix') format('embedded-opentype'),
	url('/assets/icon-font/Glyphter.woff') format('woff'),
	url('/assets/icon-font/Glyphter.ttf') format('truetype'),
	url('/assets/icon-font/Glyphter.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

[class*='icon-'] {
	position: relative;

	margin-right: rem-calc(14 + 30); //margin + icon width
}

[class*='icon-']:before {
	position: absolute;
	left: 0;
	top: 50%;

	display: inline-block;

	transform: translate(0, -50%);

	font-family: 'Glyphter', serif;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: rem-calc(30);
}

.icon-home:before {
	content: '\0043';
}
.icon-shipment:before {
	content: '\0042';
}

.icon-logout:before {
	content: '\0044';
}

.icon-news:before {
	content: '\0045';
}

.icon-person:before {
	content: '\0046';
}

.icon-rechner:before {
	content: '\0047';
}

.icon-sprache:before {
	content: '\0048';
}

.icon-connection-lost {
	margin-right: 55px;

	&:before {
		content: '\0049';
		font-size: 40px;
	}
}

.icon-person-plus:before {
	content: '\004a';
}

.icon-delete:before {
	content: '\004b';
}

.icon-edit:before {
	content: '\004c';
}

.icon-wishlist:before {
	content: '\004d';
}

.icon-catalogue:before {
	content: '\004e';
}

.icon-download-area:before {
	content: '\004f';
}

.icon-cost-benefit:before {
	content: '\0050';
}

.icon-plus:before {
	content: '\0051';
}

.icon-minus:before {
	content: '\0052';
}

.icon-arrow_left:before {
	content: '\0053';
}

.icon-icon-baender:before {
	content: '\0054';
}

.icon-icon-blattware:before {
	content: '\0055';
}

.icon-icon-jumborolle:before {
	content: '\0056';
}

.icon-icon-rollen:before {
	content: '\0057';
}

.icon-icon-scheiben:before {
	content: '\0058';
}

.icon-icon-zubehoer:before {
	content: '\0059';
}

.icon-info:before {
	content: '\005a';
}

.icon-cart:before {
	content: '\0061';
}

.icon-shipment:before {
	content: '\0062';
}
