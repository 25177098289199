.cost-benefit {

	&__red {
		-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
		color-adjust: exact !important; /* Firefox 48 – 96 */
		print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
		color: red !important;
	}

	&__green {
		-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
		color-adjust: exact !important; /* Firefox 48 – 96 */
		print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
		color: green !important;
	}

	&__border-top {
		border-top: 4px solid $black;
	}

	&__sum {
		position: relative;

		&:before {
			content: '';
			border-top: 1px solid $black;
			border-bottom: 1px solid $black;
			height: 4px;
			width: 100%;
			position: absolute;
			top: -1px;
			left: 0;
		}
	}
}
