$max-space: 10;

$spacings: (
		'padding',
		'margin'
);

$directions: (
		'top',
		'right',
		'bottom',
		'left'
);

@for $i from 0 through $max-space {

	@each $direction in $directions {

		@each $spacing in $spacings {

			.#{$spacing}-#{$direction}-#{$i} {

				#{$spacing}-#{$direction}: #{$i}rem;
			}
		}
	}
}
