.download-area {
	&__sticky-nav {
		position: sticky;
		z-index: 2;
		top: 0;

	}

	&__flex {

		display: flex;
	}

	&__headline-area {

		margin-top: 6rem;
		display: flex;
		justify-content: space-between;
	}

	&__button {

		padding: 9px 14px;
		white-space: nowrap;

		@include breakpoint(medium) {
			padding: 18px 28px;
			white-space: normal;
		}

		border: 3px solid $primary-color;
		background: transparent;
		transition: border-color .25s ease, background-color .25s ease, color .25s ease;

		color: $primary-color;

		// states
		&:focus,
		&:hover {

			border-color: scale-color($primary-color, $lightness: -20%);
		}

		&.disabled {

			opacity: 1;
			cursor: auto;
			background-color: transparent;
			border-color: $input-background;

			color: $input-background;

			&:focus,
			&:hover {

				background-color: transparent;
				border-color: $input-background;

				color: $input-background;
			}
		}

		&.active {
			background-color: $primary-color;
			color: $white;
		}

		margin: 0;

		&__list {
			@extend .button__list;

			padding: .5rem 0;
			overflow-x: scroll;

			background: $white;

			@include breakpoint(medium) {
				padding: .25rem .25rem .25rem 0;
				overflow-x: unset;
				flex-wrap: wrap;

			}

		}
	}

	&__edit {

		margin-left: .5rem;

		cursor: pointer;
	}

	&__delete {

		cursor: pointer;
	}

	&__custom-scrollbar {

		/* Width */
		&::-webkit-scrollbar {
			height: .3rem;

		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: $header-footer-bg-color;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: map-get($foundation-palette, primary);
			border-radius: 1rem;
		}

	}
}

