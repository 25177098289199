form-select {
	position: relative;

	width: 100%;
	display: block;
}

.tooltip {

	padding: 0 .5rem;
	max-height: 0;
	width: auto;
	display: block;

	opacity: 0;
	transition: opacity .3s ease-in-out, max-height .3s ease-in-out, margin .3s ease-in-out, padding .3s ease-in-out;
	overflow: hidden;

	color: map-get($foundation-palette, secondary);

	@include breakpoint(x-medium) {
		position: absolute;
		top: 50%;
		right: 0;

		padding: .5rem;
		max-height: none;
		display: inline-block;

		transition: opacity .3s ease-in-out;
		background-color: $input-background;
		transform: translate(calc(100% + 1rem), -50%);
		overflow: visible;
	}

	@include breakpoint(large) {

		max-width: 40%;
	}

	@include breakpoint(x-large) {

		max-width: none;
	}

	&:before {
		position: absolute;
		top: 50%;
		right: 100%;

		margin-top: 8px;
		height: 0;
		width: 0;
		display: none;

		border-style: solid;
		border-color: rgba(194, 225, 245, 0);
		border-right-color: $input-background;
		border-width: 8px;
		pointer-events: none;

		content: '';

		@include breakpoint(x-medium) {

			display: block;
		}
	}

	&.visible {

		margin: 0 0 1rem;
		padding: .5rem;
		max-height: 160px;

		opacity: 1;

		@include breakpoint(x-medium) {

			margin: 0;
			max-height: none;
		}
	}
}