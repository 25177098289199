// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source
// sass-lint:disable no-color-literals, no-qualifying-elements
////
/// @group global
////
@import 'node_modules/foundation-sites/scss/util/util';

/// Font size attribute applied to `<html>` and `<body>`. We use 100% by default so the value is inherited from the user's browser settings.
/// @type Number
$global-font-size: 16px;

/// Global width of your site. Used by the grid to determine row width.
/// @type Number
$global-width: rem-calc(1200) !default;

/// Default line height for all type. `$global-lineheight` is 24px while `$global-font-size` is 16px
/// @type Number
$global-lineheight: 1.5 !default;

/// Color used for light gray UI items.
/// @type Color
$light-gray: #e6e6e6 !default;

/// Color used for medium gray UI items.
/// @type Color
$medium-gray: #cacaca !default;

/// Color used for dark gray UI items.
/// @type Color
$dark-gray: #8a8a8a !default;

/// Color used for black ui items.
/// @type Color
$black: #0a0a0a !default;

/// Color used for white ui items.
/// @type Color
$white: #fefefe !default;

///
///
$form-label-color: $white;
///
///

/// Background color of the body.
/// @type Color
$body-background: $white !default;

/// Text color of the body.
/// @type Color
$body-font-color: $black !default;

/// Font stack of the body.
/// @type List
$body-font-family: 'Open Sans', sans-serif !default;

/// Set to `true` to enable antialiased type, using the `-webkit-font-smoothing` and `-moz-osx-font-smoothing` CSS properties.
/// @type Boolean
$body-antialiased: true !default;

/// Global value used for margin on components.
/// @type Number
$global-margin: 1rem !default;

/// Global value used for padding on components.
/// @type Number
$global-padding: 1rem !default;

/// Global value used for positioning on components.
/// @type Number
$global-position: 1rem !default;

/// Global font weight used for normal type.
/// @type Keyword | Number
$global-weight-normal: normal !default;

/// Global font weight used for bold type.
/// @type Keyword | Number
$global-weight-bold: bold !default;

/// Global value used for all elements that have a border radius.
/// @type Number
$global-radius: 0 !default;

/// Global value used for all menu styles. Can be overwritten at individual menu component level.
/// @type Number
$global-menu-padding: 0.7rem 1rem !default;

/// Global value used for all menu styles. Nested margin for submenu.
$global-menu-nested-margin: 1rem !default;

/// Sets the text direction of the CSS. Can be either `ltr` or `rtl`.
/// @type Keyword
$global-text-direction: ltr !default;

/// Enables flexbox for components that support it.
/// @type Boolean
$global-flexbox: true !default;

/// Enabled responsive breakpoints for prototypes if applicable
/// @type Boolean
$global-prototype-breakpoints: false !default;

/// Button cursor's value, `auto` by default
/// @type Keyword
$global-button-cursor: auto !default;

@if not map-has-key($foundation-palette, primary) {
	@error 'In $foundation-palette, you must have a color named "primary".';
}

// Internal variables used for text direction
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// Internal variable that contains the flex justifying options
$-zf-flex-justify: -zf-flex-justify($global-text-direction);

/// Global tolerance for color pick contrast.
/// @type Number
$global-color-pick-contrast-tolerance: 0 !default;

// Internal variables used for colors
@include add-foundation-colors;

@mixin foundation-global-styles {
	@include foundation-normalize;

	// These styles are applied to a <meta> tag, which is read by the Foundation JavaScript
	.foundation-mq {
		font-family: '#{-zf-bp-serialize($breakpoints)}';
	}

	html {
		box-sizing: border-box;
		font-size: $global-font-size;
	}

	// Set box-sizing globally to handle padding and border widths
	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}

	// Default body styles
	body {
		margin: 0;
		padding: 0;

		background: $body-background;

		font-family: $body-font-family;
		font-weight: $global-weight-normal;
		line-height: $global-lineheight;
		color: $body-font-color;

		@if ($body-antialiased) {
			-webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
		}
	}

	img {
		// Get rid of gap under images by making them display: inline-block; by default
		display: inline-block;
		vertical-align: middle;

		// Grid defaults to get images and embeds to work properly
		max-width: 100%;
		height: auto;
		-ms-interpolation-mode: bicubic;
	}

	// Make sure textarea takes on height automatically
	textarea {
		height: auto;
		min-height: 50px;
		border-radius: $global-radius;
	}

	// Make select elements are 100% width by default
	select {
		box-sizing: border-box;
		width: 100%;
		border-radius: $global-radius;
	}

	// Styles Google Maps and MapQuest embeds properly
	// sass-lint:disable-line no-ids
	.map_canvas,
	.mqa-display {
		img,
		embed,
		object {
			max-width: none !important;
		}
	}

	// Reset <button> styles created by most browsers
	button {
		@include disable-mouse-outline;
		padding: 0;
		appearance: none;
		border: 0;
		border-radius: $global-radius;
		background: transparent;
		line-height: 1;
		cursor: $global-button-cursor;
	}

	// Prevent text overflow on pre
	pre {
		overflow: auto;
	}

	// Make reset inherit font-family instead of settings sans-serif
	button,
	input,
	optgroup,
	select,
	textarea {
		font-family: inherit;
	}

	// Internal classes to show/hide elements in JavaScript
	.is-visible {
		display: block !important;
	}

	.is-hidden {
		display: none !important;
	}
}
