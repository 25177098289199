$even-tr-bg: #f5f5f5;

.table {
	$this: &;

	width: 100%;
	min-width: 6 * 11.375rem; // number of total cells in a table
	table-layout: fixed;


	thead {

		tr {

			background-color: $even-tr-bg;

			@media print {

				background-color: $even-tr-bg !important;
			}
		}
	}

	tbody {

		tr {

			background-color: transparent;

			@media print {

				background-color: transparent !important;
			}

			&:nth-child(even) {

				background-color: $even-tr-bg;

				@media print {

					background-color: $even-tr-bg !important;
				}
			}
		}
	}

	th,
	td {

		padding: 1rem .6875rem;
	}

	// simplebar
	&__scroll-bar {

		.simplebar-track {

			&.simplebar-horizontal {
				position: static;
				bottom: auto;
				top: auto;

				height: .5rem;
				margin-top: -.5rem;

				background-color: $input-background;

				&[style='visibility: visible;'] {

					margin-top: 0;
					margin-bottom: 2rem;

					@include breakpoint(x-medium) {

						transform: translateY(-2rem);
					}
				}

				.simplebar-scrollbar {
					position: relative;
					top: auto;

					height: 100%;

					&:before {
						left: 0;
						right: 0;

						border-radius: 0;
						opacity: 1;
						background-color: $secondary-color;
					}
				}
			}
		}
	}

	&__scroll-wrapper {

		overflow-x: auto;
	}

	// externalize default '.table' styles to this modifier, if '.table' needs to be overridden
	&--wishlist {

		margin-bottom: 1rem;

		@include breakpoint(x-medium) {

			margin-bottom: 3rem;
		}

		tr {

			vertical-align: top;
		}
	}

	&--print {

		margin-bottom: 1cm;

		@media print {

			min-width: 0;
		}

		tr {

			vertical-align: top;
		}

		th,
		td {

			padding: .5rem .6875rem;

			@media print {

				font-size: 8pt;
			}
		}
	}

	// overwrites some default table values in the download area
	&--download-area {

		table-layout: auto;

		th,
		td {

			hyphens: auto;
		}

		#{$this}__min-width {

			width: 20%;
		}
	}
}
