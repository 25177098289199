/**
 * Foundation for Sites by ZURB
 * Version 6.5.0-rc.2
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
// Dependencies
@import 'node_modules/foundation-sites/scss/vendor/normalize';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/true';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/contain';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/purge';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/remove';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/replace';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/to-list';
// --- Settings ---
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';
@import "./foundation-settings";
// --- Components ---
// Utilities
@import 'node_modules/foundation-sites/scss/util/util';
// Global styles
@import 'foundation-global';
@import 'node_modules/foundation-sites/scss/forms/forms';
@import 'node_modules/foundation-sites/scss/typography/typography';
// Grids
@import 'node_modules/foundation-sites/scss/grid/grid';
@import 'node_modules/foundation-sites/scss/xy-grid/xy-grid';
// Generic components
@import 'node_modules/foundation-sites/scss/components/button';
@import 'node_modules/foundation-sites/scss/components/button-group';
//@import 'node_modules/foundation-sites/scss/components/close-button';
//@import 'node_modules/foundation-sites/scss/components/label';
//@import 'node_modules/foundation-sites/scss/components/progress-bar';
//@import 'node_modules/foundation-sites/scss/components/slider';
//@import 'node_modules/foundation-sites/scss/components/switch';
//@import 'node_modules/foundation-sites/scss/components/table';
// Basic components
//@import 'node_modules/foundation-sites/scss/components/badge';
//@import 'node_modules/foundation-sites/scss/components/breadcrumbs';
//@import 'node_modules/foundation-sites/scss/components/callout';
//@import 'node_modules/foundation-sites/scss/components/card';
//@import 'node_modules/foundation-sites/scss/components/dropdown';
//@import 'node_modules/foundation-sites/scss/components/pagination';
//@import 'node_modules/foundation-sites/scss/components/tooltip';
// Containers
//@import 'node_modules/foundation-sites/scss/components/accordion';
//@import 'node_modules/foundation-sites/scss/components/media-object';
//@import 'node_modules/foundation-sites/scss/components/orbit';
//@import 'node_modules/foundation-sites/scss/components/responsive-embed';
//@import 'node_modules/foundation-sites/scss/components/tabs';
//@import 'node_modules/foundation-sites/scss/components/thumbnail';
// Menu-based containers
//@import 'node_modules/foundation-sites/scss/components/menu';
//@import 'node_modules/foundation-sites/scss/components/menu-icon';
//@import 'node_modules/foundation-sites/scss/components/accordion-menu';
//@import 'node_modules/foundation-sites/scss/components/drilldown';
//@import 'node_modules/foundation-sites/scss/components/dropdown-menu';
// Layout components
//@import 'node_modules/foundation-sites/scss/components/off-canvas';
//@import 'node_modules/foundation-sites/scss/components/reveal';
//@import 'node_modules/foundation-sites/scss/components/sticky';
//@import 'node_modules/foundation-sites/scss/components/title-bar';
//@import 'node_modules/foundation-sites/scss/components/top-bar';
// Helpers
@import 'node_modules/foundation-sites/scss/components/float';
@import 'node_modules/foundation-sites/scss/components/flex';
@import 'node_modules/foundation-sites/scss/components/visibility';
@import 'node_modules/foundation-sites/scss/prototype/prototype';

@mixin foundation-everything(
	$flex: true,
	$prototype: false
) {
	@if $flex {
		$global-flexbox: true !global;
	}

	// Global styles
	@include foundation-global-styles;
	@include foundation-forms;
	@include foundation-typography;

	// Grids
	@if not $flex {
		@include foundation-grid;
	} @else {
		@if $xy-grid {
			@include foundation-xy-grid-classes;
		} @else {
			@include foundation-flex-grid;
		}
	}

	// Generic components
	@include foundation-button;
	@include foundation-button-group;
	//@include foundation-close-button;
	//@include foundation-label;
	//@include foundation-progress-bar;
	//@include foundation-slider;
	//@include foundation-switch;
	//@include foundation-table;
	// Basic components
	//@include foundation-badge;
	//@include foundation-breadcrumbs;
	//@include foundation-callout;
	//@include foundation-card;
	//@include foundation-dropdown;
	//@include foundation-pagination;
	//@include foundation-tooltip;

	// Containers
	//@include foundation-accordion;
	//@include foundation-media-object;
	//@include foundation-orbit;
	//@include foundation-responsive-embed;
	//@include foundation-tabs;
	//@include foundation-thumbnail;
	// Menu-based containers
	//@include foundation-menu;
	//@include foundation-menu-icon;
	//@include foundation-accordion-menu;
	//@include foundation-drilldown-menu;
	//@include foundation-dropdown-menu;

	// Layout components
	//@include foundation-off-canvas;
	//@include foundation-reveal;
	//@include foundation-sticky;
	//@include foundation-title-bar;
	//@include foundation-top-bar;

	// Helpers
	@include foundation-float-classes;
	@if $flex {
		@include foundation-flex-classes;
	}
	@include foundation-visibility-classes;
	@if $prototype {
		@include foundation-prototype-classes;
	}
}

@include foundation-everything();
