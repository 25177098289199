.wishlist {

	&__list {

		margin-bottom: 2rem;

		> * + * {
			margin-top: 1rem;
		}
	}

	&__subline {

		&#{&} {

			margin-top: 0;
			margin-bottom: 1rem;

			font-weight: $global-weight-bold;
		}
	}

	&__form {

		display: flex;
		flex-direction: column;
		gap: 1rem;

		@include breakpoint(medium) {

			flex-direction: row;
		}

		@include breakpoint(x-medium) {

			flex-direction: column;
		}

		@include breakpoint(large) {

			flex-direction: row;
		}
	}

	&__form-input {

		flex-grow: 1;
		width: 100%;

		@include breakpoint(medium) {

			width: auto;
		}

		@include breakpoint(x-medium) {

			width: 100%;
		}

		@include breakpoint(large) {

			width: auto;
		}
	}

	&__submit {

		margin-left: 1rem;
	}

	// modal styles
	&__modal {

		&__list {

			overflow: hidden;
			margin-bottom: 2rem;
		}

		&__item {

			margin-bottom: 1rem;

			&:last-child {

				margin-bottom: 0;
			}
		}

		&__button {
			position: relative;

			padding: 1rem 1.3125rem;
			width: 100%;
			display: block;

			background-color: $grey-07;
			transition: background-color .25s ease, color .25s ease;
			cursor: pointer;

			color: $c-font;
			text-align: left;
			line-height: 1.5rem;
			font-weight: $global-weight-bold;

			// states
			&:hover {

				background-color: $secondary-color;

				color: $white;
			}

			i {
				position: absolute;
				right: 0;
				top: -1px;

				height: calc(100% + 2px);
				width: 2.5rem;
				display: flex;
				justify-content: center;
				align-items: center;

				background-color: $grey-04;
				transition: background-color .25s ease;
			}

			&:hover {

				i {

					background-color: $grey-07;
				}
			}
		}
	}

	&__info-bar {

		padding: 1rem;

		background-color: $primary-color;

		color: $white;
	}

	&__remove-product {

		cursor: pointer;

		svg {

			path {

				transition: fill .25s ease;
			}
		}

		&:hover {

			svg {

				path {

					fill: $primary-color;
				}
			}
		}
	}

	// button group for exports in wishlist-detail
	&__export-group {

		margin-bottom: 1.25rem;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;

		@include breakpoint(x-medium) {

			margin-bottom: 0;
			justify-content: flex-end;
		}

		// why
		&__inner {

			margin-bottom: 18px;

			@include breakpoint(medium) {

				margin-bottom: 22px;
			}

			@include breakpoint(large) {

				margin-bottom: 35px;
			}
		}

		a {

			margin-right: 1.5rem;

			cursor: pointer;

			font-size: 1rem;
			line-height: 1.5rem;
			font-weight: $global-weight-bold;
			color: $primary-color;
		}
	}

	// externalized HTML Template meant to display wishlist data and print it
	&__print-view {

		&__headline {

			@media print {

				margin-bottom: 1.5cm;

				font-size: 18pt;
			}
		}

		&__subline {

			@media print {

				margin-bottom: .4cm;

				font-size: 12pt;
			}
		}

		&__logo {

			@media print {

				margin-bottom: 1.5cm;

				height: 1.4cm;
			}
		}
	}
}
