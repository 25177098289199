.action-card {
	display: flex;
	align-items: stretch;
	flex-wrap: nowrap;

	&__anchor {

		padding: 1rem;
		display: block;
		flex-grow: 1;

		background-color: $link-bg;
		color: $body-font-color;

		// removing default anchor underline
		&:before {

			content: none;
		}

		// states
		&:focus,
		&:hover {

			color: $body-font-color;
		}
	}

	&__delete,
	&__edit {

		display: flex;
		justify-content: center;
		align-items: center;

		cursor: pointer;
		transition: background-color .25s ease;

		svg {

			path {

				transition: fill .25s ease;
			}
		}
	}

	&__edit {

		width: 2.5rem;

		background-color: $primary-color;

		&:hover {

			background-color: scale-color($primary-color, $lightness: -20%);
		}
	}

	&__delete {

		width: 3.5rem;

		background-color: $link-bg;

		&:hover {

			svg {

				path {

					fill: scale-color($secondary-color, $lightness: -20%);
				}
			}
		}
	}
}
