// Pure CSS Accordion
.accordion {
	$class: &;

	margin: 0 0 30px;

	overflow: hidden;

	&__item {

		width: 100%;

		background: $input-background;
	}

	&__content {

		padding: 0 20px;
		max-height: 0;

		transition: max-height .3s ease-in-out, padding .3s ease-in-out;

		p,
		ul,
		ol {

			margin: 0 0 20px;

			&:last-child {

				margin: 0;
			}
		}

		ul {

			li {
				position: relative;

				margin: 0 0 5px;
				padding: 0 0 0 20px;

				&:before {
					position: absolute;
					left: 0;
					top: 9px;

					width: 5px;
					height: 5px;
					display: block;

					background: $primary-color;
					border-radius: 50%;

					content: '';
				}
			}
		}
	}

	&__headline {

		&__icon {

			display: block;

			transform: rotate(180deg);
			transition: transform .2s ease-in-out;
		}
	}

	&__check {
		// Hide Checkbox
		position: absolute;

		width: 0;
		height: 0;

		opacity: 0;

		font-size: 0;

		// Headline / opener
		+ #{ $class }__headline {

			margin: 0;
			padding: 20px;
			width: 100%;
			display: flex;
			justify-content: space-between;

			align-items: center;
			cursor: pointer;

			font-weight: $global-weight-bold;
			color: $black;
			font-size: 1rem;
			line-height: 1;
		}

		// Opened state
		&:checked {
			+ #{ $class }__headline {
				#{ $class }__headline__icon {

					transform: rotate(0);
				}
			}

			~ #{ $class }__content {

				padding: 0 20px 20px;
				max-height: 100vh;
			}
		}
	}
}
