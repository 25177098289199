.notification {

	margin-bottom: 20px;
	padding: 8px 20px;

	font-size: 1rem;

	&.primary {

		background-color: map-get($foundation-palette, primary);

		color: $white;
	}

	&.hint {
		position: relative;

		margin-top: 40px;
		margin-bottom: 0;
		padding: 14px 20px 14px 60px;

		background-color: $input-background;

		color: map-get($foundation-palette, secondary);

		@include breakpoint(x-large) {

			margin-top: 70px;
		}

		&:before {
			position: absolute;
			left: 20px;
			top: 14px;

			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;

			border-radius: 50%;
			background: map-get($foundation-palette, secondary);

			color: $white;
			font-size: 20px;
			content: '!';
		}
	}
}
